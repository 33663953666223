import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { PlayArrow, Pause, Add, Watch } from '@mui/icons-material';

const API_URL = 'https://chimeramultimedia.com/peti';


function App() {
  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [assignee, setAssignee] = useState('');
  const [reporter, setReporter] = useState('');
  const [description, setDescription] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectContact, setProjectContact] = useState('');
  const [projectId, setProjectId] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [view, setView] = useState('tasks'); // State to manage current view
  const [anchorEl, setAnchorEl] = useState(null); // State to handle menu open/close
  const [openTaskDialog, setOpenTaskDialog] = useState(false); // State for task dialog
  const [openProjectDialog, setOpenProjectDialog] = useState(false); // State for project dialog

  // Fetch tasks and projects from API
  const fetchData = async () => {
    try {
      setLoading(true);
      const [taskResponse, projectResponse] = await Promise.all([
        axios.get(`${API_URL}/tasks/`),
        axios.get(`${API_URL}/projects/`),
      ]);
      setTasks(taskResponse.data);
      setProjects(projectResponse.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching data');
      setLoading(false);
    }
  };
useEffect(() => {

    fetchData();
  }, []);

  // Create a new project
  const createProject = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/projects/`, {
        id: null,
        name: projectName,
        contact: projectContact,
      });
      setProjects((prevProjects) => [...prevProjects, { ...response.data, name: projectName, contact: projectContact }]);
      setProjectName('');
      setProjectContact('');
      setOpenProjectDialog(false); // Close dialog after creation
      setLoading(false);
    } catch (err) {
      setError('Error creating project');
      setLoading(false);
    }
  };

  // Create a new task
  const createTask = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/tasks/`, {
        id: null,
        project_id: projectId,
        name: taskName,
        assignee,
        reporter,
        description,
      });
      setTasks((prevTasks) => [...prevTasks, { ...response.data, name: taskName, assignee, reporter, description, time_interval_logs: [], direct_logs: []}]);
      setTaskName('');
      setAssignee('');
      setReporter('');
      setDescription('');
      fetchData(); // Refresh tasks after creation
      setOpenTaskDialog(false); // Close dialog after creation
      setLoading(false);
    } catch (err) {
      setError('Error creating task');
      setLoading(false);
    }
  };

  // Start or stop a task
  const toggleTaskStatus = async (taskId, notStarted) => {
    try {
      setLoading(true);
      if (notStarted) {
        await axios.post(`${API_URL}/tasks/${taskId}/start`);
        alert('Task started successfully');
      } else {
        await axios.post(`${API_URL}/tasks/${taskId}/finish`);
        alert('Task finished successfully');
      }
      fetchData(); // Refresh tasks after status change
      setLoading(false);
    } catch (err) {
      setError('Error toggling task status');
      setLoading(false);
    }
  };

  // Log work on a task
  const logWork = async (taskId) => {
    const description = prompt('Enter work description:');
    const minutesLogged = parseFloat(prompt('Enter worked minutes:'));
    if (!description || isNaN(minutesLogged)) {
      alert('Invalid input. Please provide a description and valid minutes.');
      return;
    }
    try {
      setLoading(true);
      await axios.post(`${API_URL}/tasks/${taskId}/log`, { description, minutes_logged: minutesLogged });
      alert('Work logged successfully');
      setLoading(false);
    } catch (err) {
      setError('Error logging work');
      setLoading(false);
    }
  };

  // Calculate total hours per project in the last month
  const calculateTotalHoursPerProject = () => {
    const getTimeDifferenceInSeconds = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return (endDate - startDate) / 1000; // Difference in seconds
    };
    



    // TODO: filter this to only include tasks from the last month
    const minutesPerProject = projects.map((project) => {
      const projectTasks = tasks.filter((task) => task.project_id === project.id);
      const totalMinutes = projectTasks.reduce((sum, task) => {
        // const startStopMinutes = task.time_interval_logs.reduce((taskSum, startStop) => taskSum + log.minutes_logged, 0);
        const logMinutes = task.direct_logs.reduce((taskSum, log) => taskSum + log.minutes_logged, 0);
        const totalTimeSpent = task.time_interval_logs.reduce((total, interval) => {
          return total + getTimeDifferenceInSeconds(interval.start_time, interval.end_time);
        }, 0);
  

        return sum + logMinutes * 60 + totalTimeSpent;
      }, 0);
        return { projectName: project.name, totalMinutes };
    });

    return minutesPerProject;
  };

  // Menu handlers
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewChange = (newView) => {
    setView(newView);
    setAnchorEl(null);
  };

  return (
    <Container>
      {/* AppBar for navigation */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Task and Project Manager
          </Typography>
          <Button color="inherit" onClick={handleMenuClick}>
            Menu
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={() => handleViewChange('tasks')}>List and Add Tasks</MenuItem>
            <MenuItem onClick={() => handleViewChange('projects')}>Add Projects and Display Hours</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {error && <Typography color="error">{error}</Typography>}

      {view === 'tasks' ? (
        // Tasks View
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setOpenTaskDialog(true)}
            style={{ marginTop: '20px' }}
          >
            Add Task
          </Button>

          {/* Filter Section */}
          <Typography variant="h6" style={{ marginTop: '20px' }}>Filter Tasks</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Assignee</InputLabel>
            <Select value={assigneeFilter} onChange={(e) => setAssigneeFilter(e.target.value)}>
              <MenuItem value="">All</MenuItem>
              {['Péter', 'Pál', 'Ágnes'].map((assignee) => (
                <MenuItem key={assignee} value={assignee}>
                  {assignee}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <MenuItem value="">All</MenuItem>
              {['todo', 'in progress', 'done'].map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Display Tasks */}
          <Typography variant="h6" style={{ marginTop: '20px' }}>Tasks</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Assignee</TableCell>
                  <TableCell>Reporter</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.filter(
                  (task) =>
                    (assigneeFilter === '' || task.assignee === assigneeFilter) &&
                    (statusFilter === '' || task.state === statusFilter)
                ).map((task) => (
                  <TableRow key={task.id}>
                    <TableCell>{task.name}</TableCell>
                    <TableCell>{task.assignee}</TableCell>
                    <TableCell>{task.reporter}</TableCell>
                    <TableCell>{task.state}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => toggleTaskStatus(task.id, task.time_interval_logs.length == 0 || task.time_interval_logs[task.time_interval_logs.length-1].end_time != null)} color="primary">
                        {task.time_interval_logs.length == 0 || task.time_interval_logs[task.time_interval_logs.length-1].end_time != null? <PlayArrow /> : <Pause />}
                      </IconButton>
                      <IconButton onClick={() => logWork(task.id)} color="primary">
                        <Watch />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Dialog for Adding Task */}
          <Dialog open={openTaskDialog} onClose={() => setOpenTaskDialog(false)}>
            <DialogTitle>Add Task</DialogTitle>
            <DialogContent>
              <DialogContentText>Fill in the task details below.</DialogContentText>
              <FormControl fullWidth margin="normal">
                <InputLabel>Project</InputLabel>
                <Select value={projectId} onChange={(e) => setProjectId(e.target.value)}>
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Task Name"
                value={taskName}
                onChange={(e) => setTaskName(e.target.value)}
                fullWidth
                margin="normal"
              />
              {/* <TextField
                label="Assignee"
                value={assignee}
                onChange={(e) => setAssignee(e.target.value)}
                fullWidth
                margin="normal"
              /> */}

              <FormControl fullWidth margin="normal">
                <InputLabel>Assignee</InputLabel>
                <Select value={assignee} onChange={(e) => setAssignee(e.target.value)}>
                  {["Péter", "Pál", "Ágnes"].map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Reporter</InputLabel>
                <Select value={reporter} onChange={(e) => setReporter(e.target.value)}>
                  {["Péter", "Pál", "Ágnes"].map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenTaskDialog(false)} color="secondary">Cancel</Button>
              <Button onClick={createTask} color="primary">Create Task</Button>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (
        // Projects View
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setOpenProjectDialog(true)}
            style={{ marginTop: '20px' }}
          >
            Add Project
          </Button>

          {/* Display Total Hours per Project in Last Month */}
          <Typography variant="h6" style={{ marginTop: '20px' }}>Total minutes per Project (Last Month)</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Total Seconds</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calculateTotalHoursPerProject().map((project) => (
                  <TableRow key={project.projectName}>
                    <TableCell>{project.projectName}</TableCell>
                    <TableCell>{project.totalMinutes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Dialog for Adding Project */}
          <Dialog open={openProjectDialog} onClose={() => setOpenProjectDialog(false)}>
            <DialogTitle>Add Project</DialogTitle>
            <DialogContent>
              <DialogContentText>Fill in the project details below.</DialogContentText>
              <TextField
                label="Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Project Contact"
                value={projectContact}
                onChange={(e) => setProjectContact(e.target.value)}
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenProjectDialog(false)} color="secondary">Cancel</Button>
              <Button onClick={createProject} color="primary">Create Project</Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Container>
  );
}

export default App;
